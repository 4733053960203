export const userReducer = (state = {isLoggedIn: false, isInitialized: false}, action) => {
    switch (action.type) {
        case "SET_USER":
            return state = action.payload;
        case "SET_BALANCE":
            return state = {...state, balance: action.payload};
        default:
            return state;
    }
};


export const setUser = (user) => {
    return {
        type: "SET_USER",
        payload: user
    }
}