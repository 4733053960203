import React from 'react'
import Box from '@mui/material/Box'
import MineItem from './MineItem'

export default function MineField({field, status}) {
  return (
    <Box sx={{ // MINE Container
        padding: "3rem",
        display: "flex",
        flexGrow: "1",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        backgroundColor: "rgb(31 34 37)",
    }}>
        <Box sx={{
                maxWidth: "36rem",
                width: "100%",

        }}>
            <Box sx={{
                display: "grid",
                gap: ".75rem",
                width: "100%",
                gridTemplateColumns: "repeat(5, minmax(0px, 1fr))",
                gridTemplateRows: "repeat(5, minmax(0px, 1fr))",

                // user should know if the game is not running anymore (change color hue
                filter: status == "running" ? "none" : "blur(3px) brightness(.8) grayscale(.1)",

            }}>
                
                {[...Array(25)].map((_, i) => (
                    <MineItem key={i} id={i} value={field[i]} />
                ))}
            </Box>

        </Box>

    </Box>
  )
}
