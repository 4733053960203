import React from "react";
import { Box } from "@mui/material";
import BettingButton from "./BettingButton";
import PaymentSelector from "./PaymentSelector";
import BetsList from "./BetsList";

export default function BettingArea({rouletteBets, canBet}) {
    const [bettingAmount, setBettingAmount] = React.useState("0.1");
    return (
        <Box
            className="BettingContainer"
            sx={{
                color: "rgb(132 139 141)",
                display: "flex",
                width: "100%",
                alignItems: "center",
                gap: "1.25rem",
                boxSizing: "border-box",
                flexDirection: "column",
            }}
        >
            <PaymentSelector bettingAmount={bettingAmount} setBettingAmount={setBettingAmount}/>
            <Box
                className="PlaceContainer"
                sx={{
                    display: "flex",
                    width: "100%",
                    gap: "1rem",
                    boxSizing: "border-box",
                    // justifyContent: "center",
                }}
            >
                <BettingButton text="Place Bet" amount={2}color="blue" gradient="linear-gradient(rgb(96, 165, 250), rgb(59, 130, 246))" canBet={canBet} bettingAmount={bettingAmount}/>
                <BettingButton text="Place Bet" amount={2} color="red" gradient="linear-gradient(rgb(207, 27, 27), rgb(191, 27, 27))" canBet={canBet} bettingAmount={bettingAmount}/>
                <BettingButton text="Place Bet" amount={13} color="gold" gradient="linear-gradient(rgb(227, 175, 2), rgb(223, 168, 2))" canBet={canBet} bettingAmount={bettingAmount}/>
            </Box>

            <BetsList rouletteBets={rouletteBets}/>
        </Box>
    );
}
