import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useMediaQuery } from "@mui/material";
export default function LastGames({ games = [] }) {
    const displayGames = useMediaQuery("(min-width: 30rem)") ? 10 : 6;

    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: ".5rem",
            }}
        >
            <a>Last games</a>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: ".5rem",
                    width: "100%",
                    // ml: "-2.7rem"
                }}
            >
                {games.slice(0, displayGames).map((color, i) => (
                    <LastGameEntry key={i} color={color} />
                ))}
            </Box>
        </Box>
    );
}

function LastGameEntry({ color }) {
    let colorData;
    switch (color) {
        case "red":
            colorData = "#cf1b1b";
            break;
        case "blue":
            colorData = "#0074D9";

            break;
        case "gold":
            colorData = "#e3af02";
            break;
    }
    return (
        <Box
            sx={{
                backgroundColor: colorData,

                borderRadius: "40%",
                width: "2.2rem",
                aspectRatio: "1 / 1",
                boxShadow: "rgba(0, 0, 0, 0.5) 0px 0px 12px 0px inset",
                transition: "all 0.25s ease-in-out 0s",
                // minWidth: "2.2rem",
            }}
        ></Box>
    );
}
