
const DEV = false;

const API_BASEURL = DEV
    ? "http://localhost:5000"
    : "https://api.rustyscrap.net";
const SOCKET_URL = DEV ? "ws://localhost:5001" : "wss://ws.rustyscrap.net";
const MAIN_URL = DEV ? "http://localhost:3000" : "https://rustyscrap.net";


export { API_BASEURL, SOCKET_URL, MAIN_URL };
