import React from "react";
import { Box } from "@mui/material";
import BetEntry from "./BetEntry";

const rouletteColors = ["blue", "red", "gold"];

export default function BetsList({ rouletteBets }) {
    return (
        <Box
            sx={{
                gridTemplateColumns: "repeat(3, minmax(0px, 1fr))",
                width: "100%",
                display: "grid",
                gap: "1rem",
                boxSizing: "border-box",
            }}
        >
            {rouletteColors.map((color, i) => (
                <BetsColumn color={color} key={i} bets={rouletteBets[color]} />
            ))}
        </Box>
    );
}

function BetsColumn({ bets }) {
    return (
        <Box
            sx={{
                minHeight: "28rem",
                display: "flex",
                width: "100%",
                flexDirection: "column",
                gap: "1rem",
                boxSizing: "border-box",
            }}
        >
            {bets.map((bet, i) => {
                return (
                    <BetEntry
                        username={bet.username}
                        avatar={bet.avatar}
                        amount={bet.amount}
                    />
                );
            })}
        </Box>
    );
}
