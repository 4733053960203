import React from "react";
import { Box } from "@mui/material";

export default function RouletteItem({ color }) {
    // if width is less than 10 rem


    let colorData;
    switch (color) {
        case "red":
            colorData = {
                color1: "#cf1b1b",
                color2: "#cf1b1b",
            };
            break;
        case "blue":
            colorData = {
                color1: "#0074D9"
            };
            break;
        case "gold":
            colorData = {
                color1: "#e3af02"
            };
            break;
    }
    return (
        <Box
            sx={{
                display: "flex",
                height: "100%",
                alignItems: "center",
                boxSizing: "border-box",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // backgroundColor: cssColor,
                    // backgroundImage: "linear-gradient(to bottom)",
                    marginLeft: ".5rem",
                    marginRight: ".5rem",
                    width: "7rem",
                    borderRadius: "30%", // .375rem
                    height: "7rem",
                    backgroundColor: colorData.color1,
                    boxShadow:
                        "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.5) 0px 0px 12px 0px inset",
                }}
            >
                {calculateImage(color)}
            </Box>
        </Box>
    );
}

function calculateImage(color) {
    switch (color) {
        case "red":
            return (
                <img
                    src="https://rustyscrap.s3.eu-west-3.amazonaws.com/schwert.png"
                    alt="red"
                    style={{
                        width: "4.5rem",
                        aspectRatio: "1/1",
                        opacity: "0.7",
                    }}
                />
            );
        case "blue":
            return (
                <img
                    src="https://rustyscrap.s3.eu-west-3.amazonaws.com/schwert.png"
                    alt="blue"
                    style={{
                        width: "4.5rem",
                        aspectRatio: "1/1",
                        opacity: "0.7",
                    }}
                />
            );
        case "gold":
            return (
                <img
                    src="https://rustyscrap.s3.eu-west-3.amazonaws.com/krone.png"
                    alt="gold"
                    style={{
                        width: "4.5rem",
                        aspectRatio: "1/1",
                        opacity: "0.8",
                    }}
                />
            );
    }
}

/*
export default function RouletteItem({ color }) {
    let cssColor;
    switch (color) {
        case "red":
            cssColor = "#cf1b1b";
            break;
        case "blue":
            cssColor = "#0074D9";
            break;
        case "gold":
            cssColor = "#e3af02";
            break;
    }
    return (
        <Box
        
            sx={{
                display: "flex",
                height: "100%",
                alignItems: "center",
                boxSizing: "border-box",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: cssColor,
                    backgroundImage: "linear-gradient(to bottom)",
                    marginLeft: ".5rem",
                    marginRight: ".5rem",
                    width: "7rem",
                    borderRadius: "30%", // .375rem
                    height: "7rem",
                    boxShadow:
                        "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.5) 0px 0px 12px 0px inset",
                }}
            >
                {calculateImage(color)}
            </Box>
        </Box>
    );
}
*/
