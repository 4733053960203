import React from "react";
import styled from "@emotion/styled";
import Colors from "../Modules/Colors";

export default function FancyButton({ text, sx, onClick, color }) {
    const Button = styled.button`
        // change the background color to the ui_green color from the Colors module
        background-color: ${color || Colors.ui_grey};
        border-radius: 6px;
        border: none;
        color: white;
        padding: 8px 30px;
        font-family: "Roboto", sans-serif;
        font-size: 1.15rem;
        box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.45);
    `;

    return (
        <>
            <Button sx={sx} onClick={onClick}>
                {text}
            </Button>
        </>
    );
}
