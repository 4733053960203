import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import Colors from "../../Modules/Colors";
import FancyButton from "../FancyButton";
import CoinDisplay from "./CoinDisplay";
import AxiosClient from "../../Modules/AxiosClient";

export default function NavigationBar() {
    const navigate = useNavigate();
    const user = useSelector((state) => state.userReducer);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                sx={{
                    backgroundColor: Colors.grey2,
                }}
            >
                <Toolbar
                    sx={{
                        justifyContent: "space-between",
                        backgroundColor: Colors.grey2,
                    }}
                >
                    <Stack
                        direction="row"
                        onClick={() => {
                            navigate("/");
                        }}
                        sx={{ cursor: "pointer" }}
                    >
                        <Avatar src="https://rustyscrap.s3.eu-west-3.amazonaws.com/scrap.png"></Avatar>

                        <Typography
                            variant="h4"
                            fontWeight="600"
                            component="div"
                            sx={{
                                ml: 3,
                                flexGrow: 1,
                                display: { xs: "none", sm: "flex" },
                                userSelect: "none",
              

                            }}
                        >
                            RustyScrap
                        </Typography>
                    </Stack>

                    <Stack direction="row" spacing={2}>
                      
                    </Stack>

                    <Stack direction="row" spacing={3}>
                    <CoinDisplay amount={user.balance} />
                      {/* <FancyButton text="Deposit" color={Colors.ui_green} sx={{cursor: "pointer"}}/>
                      <FancyButton text="Withdraw" sx={{cursor: "pointer"}}/> */}
                      {
                        user.isLoggedIn ? (
                            <Avatar src={user.avatar} sx={{ cursor: "pointer" }} />
                        ) : <FancyButton text="Login" color={Colors.ui_green} sx={{cursor: "pointer"}} onClick={async () => {
                            const res = await AxiosClient.get("/auth/oauth_url")
                            if (!res.data.error) {
                                window.location.href = res.data
                            }
                        }} />
                      }
                    </Stack>
                  
                </Toolbar>
            </AppBar>
            <Toolbar sx={{ mb: 2.4 }} />
        </Box>
    );
}

