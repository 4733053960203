import React from "react";
import { Box } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";

export default function BetEntry({ username, avatar, amount }) {
    const isMobile = useMediaQuery("(max-width: 30rem)");

    return (
        <Box
        className="BetEntry"
            sx={{
                display: "flex",
                alignItems: "center",
                gap: ".5rem",
                borderRadius: ".25rem",
                backgroundColor: "rgb(31 34 37)",
                padding: "0.625rem 0.75rem",
                lineHeight: "1.25rem",
                fontSize: "0.875rem",
                color: "white",
                boxSizing: "border-box",
                // slide in from top animation
                animation: "slideInFromTop 0.3s",
                "@keyframes slideInFromTop": {
                    from: {
                        transform: "translateY(-1rem)",
                        opacity: 0,
                    },
                    to: {
                        transform: "translateY(0)",
                        opacity: 1,
                    },
                },

            }}
        >
            <img
                src={avatar}
                style={{
                    height: "1.5rem",
                    aspectRatio: "1/1",
                    borderRadius: "0.25rem",
                    display: "block",
                    maxWidth: "100%",
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    flexGrow: 1,
                    alignItems: "center",
                    gap: ".025rem",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                }}
            >
                {!isMobile && (
                    <span
                    style={{
                        maxWidth: "100%",
                        fontFamily: "Bai Jamjuree, sans-serif",
                        fontWeight: 600,
                        color: "rgb(132 139 141)",
                    }}
                >
                    {username}
                </span>
                )}
                
                
            </Box>
            <Box sx={{
                display: "inline-flex",
                alignItems: "center",
                lineHeight: 1,
                gap: "0.375rem",
                boxSizing: "border-box",
                fontSize: "1rem",
                fontFamily: "Bai Jamjuree, sans-serif",
            }}>
                {/* <img
                    src="https://media.discordapp.net/attachments/1188913827442589730/1212465543462789220/schwert.png?ex=65f1ef88&is=65df7a88&hm=6483c1e7b5f7aea5509b9ddecdb8781e5189c070501bc0501998c97b5ef61c96&=&format=webp&quality=lossless"
                    alt="red"
                    style={{ width: "1.5rem", aspectRatio: "1/1", opacity: "0.7", filter: "invert(1)" }}
                /> */}
                <span style={{ color: "white" }}>{amount}</span>
                
            </Box>
        </Box>
    );
}
