import axios from "axios";
import { API_BASEURL } from "./Constants";


const AxiosClient = axios.create({
    baseURL: API_BASEURL,
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true,
});
export default AxiosClient
