import React, { useEffect, useRef, useState } from "react";
import anime from "animejs";
import { Box } from "@mui/material";
import RouletteItem from "./RouletteItem";
import BettingArea from "./BettingArea";
import PaymentSelector from "./PaymentSelector";
import useSound from "use-sound";
import WebsocketClient from "../../Modules/WebsocketClient";
import winSound from "../../Assets/win.mp3";
import placeBetSound from "../../Assets/placebet.mp3";
import LastGames from "./LastGames";

async function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

const rouletteColors = [
    "red",
    "blue",
    "red",
    "blue",
    "red",
    "blue",
    "red",
    "blue",
    "red",
    "blue",
    "red",
    "blue",
    "red",
    "blue",
    "gold",
];

function randomColor() {
    return Math.floor(Math.random() * rouletteColors.length);
}
function randomPos() {
    // between -3.4 and 3.4
    return Math.random() * 6.8 - 3.4;
}

export default function RoulettePage() {
    const [playWinSound] = useSound(winSound, { volume: 0.1 });
    const [playPlaceBetSound] = useSound(placeBetSound, { volume: 0.15 });
    const [rouletteBets, setRouletteBets] = useState({
        blue: [],
        red: [],
        gold: [],
    });
    const [lastGames, setLastGames] = useState([]);
    const [canBet, setCanBet] = useState(false);

    WebsocketClient.on("R#bets", async (data) => {
        setRouletteBets(data.bets);
        playPlaceBetSound();
    });
    WebsocketClient.on("R#roll", async (data) => {
        spinRoulette(data.result);
    });

    WebsocketClient.on("R#set", async (data) => {
        anime.set(".RouletteSpinner", {
            translateX: `-${data.field * 8 + 4 + 8 * 15}rem`,
        });
    });

    WebsocketClient.on("R#reset", async (data) => {
        anime({
            targets: ".BetEntry",
            translateY: ".85rem",
            opacity: 0,
            duration: 340,
            easing: "easeInOutSine",
        });

        await sleep(500);

        setRouletteBets({
            blue: [],
            red: [],
            gold: [],
        });
    });
    WebsocketClient.on("R#bettingAllowed", async (data) => {
        setCanBet(data.value);
    });
    WebsocketClient.on("R#win", async (data) => {
        playWinSound();
    });
    WebsocketClient.on("R#lastgames", async (data) => {
        setLastGames(data.games);
    });

    useEffect(() => {
        anime.set(".RouletteSpinner", {
            translateX: `-${8 * 15 - 4}rem`,
        });
    }, []);

    async function spinRoulette(result) {
        let winnigField = result;
        let randPos = randomPos();

        await sleep(100);
        anime({
            targets: ".RouletteSpinner",

            translateX: `-${winnigField * 8 + 4 + randPos + 8 * 45}rem`,
            duration: 3000,
            speed: 0.1,
            easing: "easeInOutSine",
            // call function every tick
        });
        await sleep(3400);
        anime({
            targets: ".RouletteSpinner",

            translateX: `-${winnigField * 8 + 4 + 8 * 45}rem`,
            duration: 500,
            speed: 0.1,
            easing: "easeInOutSine",
            // on finish
            complete: () => {
                anime.set(".RouletteSpinner", {
                    translateX: `-${winnigField * 8 + 4 + 8 * 15}rem`,
                });
            },
        });
    }

    return (
        <>
            <Box
                className="InnerMain"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "2.5rem",
                    boxSizing: "border-box",
                    tabSize: "4",
                }}
            >
                <LastGames games={lastGames} />
                <Box
                    className="RouletteContent"
                    sx={{
                        position: "relative",
                        display: "flex",
                        width: "100%",
                        gap: ".75rem",
                        borderRadius: ".25rem",
                        boxSizing: "border-box",
                    }}
                >
                    <Box
                        className="RouletteDivider"
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            zIndex: 10,
                            height: "9rem",
                            width: "3px",
                            backgroundColor: "rgba(255, 255, 255, 0.5)",
                            transform: "translate(-50%, -50%)",
                        }}
                    ></Box>
                    <Box
                        className="RouletteSpinnerBg"
                        sx={{
                            position: "relative",
                            width: "100%",
                            overflow: "hidden",
                            height: "8rem",
                            boxSizing: "border-box",
                            backgroundColor: "rgb(31, 34, 37)",
                            borderRadius: ".475rem",
                        }}
                    >
                        <Box
                            className="RouletteSpinner"
                            sx={{
                                position: "absolute",
                                display: "inline-flex",
                                alignItems: "center",
                                left: "50%",
                                height: "100%",
                                boxSizing: "border-box",
                            }}
                        >
                            {Array(15 * 5)
                                .fill(0)
                                .map((_, i) => {
                                    return (
                                        <RouletteItem
                                            key={i}
                                            color={
                                                rouletteColors[
                                                    i % rouletteColors.length
                                                ]
                                            }
                                        />
                                    );
                                })}
                        </Box>
                    </Box>
                </Box>

                <BettingArea rouletteBets={rouletteBets} canBet={canBet} />
            </Box>
        </>
    );
}
