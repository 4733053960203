import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { userReducer } from "./Modules/redux";
import { configureStore } from "@reduxjs/toolkit";

import { Provider } from "react-redux";
export const store = configureStore({
  reducer: {
    userReducer,
  },
  devTools: true,
});


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);
