import React from "react";
import { useLocation } from "react-router-dom";
import AxiosClient from "../../Modules/AxiosClient";
import { MAIN_URL } from "../../Modules/Constants";

export default function CallbackPage() {
    const loc = useLocation();
    const paramStr = loc.search;
    // parse paramStr
    const params = new URLSearchParams(paramStr);
    const code = params.get("xx");
    // convert params to object
    const paramsObj = {};
    params.forEach((value, key) => {
        paramsObj[key] = value;
    });

    const [error, setError] = React.useState(null);

    // add the params as a key value pair to the body (application/x-www-form-urlencoded)
    AxiosClient.post("/auth/callback" + paramStr, {
        ...paramsObj,
    }, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",    
        },
    })
        .then((res) => {
            console.log(res);
            if (res.data.error) {
                setError(res.data.error);
                return;
            }
            else {
                window.location.href = MAIN_URL;
            }
            
        })
        .catch((err) => {
            console.log(err);
        });

    return (
        <div style={{
            fontFamily: "Bai Jamjuree, sans-serif",
            fontWeight: "800",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "80vh",
            width: "100%",
            fontSize: "2.5em",
            // gradient text, animated
            background: "linear-gradient(90deg, rgba(11,117,210,1) 0%, rgba(61,92,219,1) 24%, rgba(172,42,230,1) 54%, rgba(250,0,255,1) 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            flexDirection: "column",

        }}>
            {error ? "An error occurred!!" : "Logging in, please be patient..."}
        </div>
    );
}
