import React from "react";
import Box from "@mui/material/Box";
import WebsocketClient from "../../Modules/WebsocketClient";

export default function MineItem({ id, value }) {
    return (
        <Box
            style={{
                letterSpacing: ".05em",
                padding: "0.625rem 1rem",
                fontSize: "0.875rem",
                lineHeight: "1.25rem",
                color: "white",
                position: "relative",
                cursor: "pointer",
                aspectRatio: "1 / 1",
                overflow: "hidden",
                borderRadius: ".375rem",
                borderWidth: "0px 0px 2px",
                borderColor: "rgba(0, 0, 0, 0.1)",
                background: value
                    ? "red"
                    : "linear-gradient(225deg, rgba(0, 0, 0, 0) 50%, rgba(40, 81, 50, 0.157)), rgb(42, 42, 56)",
                margin: "0",
                // outline gradient
            }}
            onClick={() =>
                WebsocketClient.send("mines", [
                    { type: "reveal", fieldIndex: id },
                ])
            }
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: ".375rem",
                }}
            >
                <Box
                    sx={{
                        backgroundImage:
                            "url(https://rustyscrap.s3.eu-west-3.amazonaws.com/diamant.png)",
                        filter: "invert(.08)",
                        position: "absolute",
                        inset: "0px",
                        backgroundSize: "90%",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        "&:hover": {
                            backgroundSize: "100%",
                            transition: "background-size 0.25s ease",
                        },
                        transition: "background-size 0.25s ease",
                    }}
                ></Box>
            </Box>
        </Box>
    );
}
