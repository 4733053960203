import React from 'react'
import Box from '@mui/material/Box'
import PaymentSelector from './PaymentSelector'
import MineField from './MineField'
import WebsocketClient from '../../Modules/WebsocketClient'

export default function MinesPage() {

  const [field, setField] = React.useState([
    0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
  ])
  const [multiplier, setMultiplier] = React.useState(1)
  const [status, setStatus] = React.useState("loading")

  WebsocketClient.on("M#field", async (data) => {
    setField(data.field)
  })

  WebsocketClient.on("M#status", async (data) => {
    setStatus(data.value)
  })

  WebsocketClient.on("M#multiplier", async (data) => {
    setMultiplier(data.value)
  })

  return (
    <main style={{
      padding: "2.5rem",
      isolation: "isolate",
      minHeight: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "80rem",
    }}>
      <Box sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        overflow: "hidden",
        borderRadius: ".25rem",

      
      }}>
        <PaymentSelector status={status} multiplier={multiplier} />
        <MineField field={field} status={status} />
      </Box>
    </main>
  )
}
