import { io } from "socket.io-client";
import AxiosClient from "./AxiosClient";
import { store } from "../index";
import { SOCKET_URL } from "./Constants";
import EventEmitter from "events";

class Websocket extends EventEmitter {
    constructor() {
        super();
        this.socket = io(SOCKET_URL, {
            withCredentials: true,  
        });
        this.socket.on("connect", () => {
            console.log("Connected to socket!", this.socket.id); // x8WIv7-mJelg7on_ALbx
        });

        this.socket.on("roulette", (event) => {
            event.forEach((e) => {
                const { type } = e;
                let data = e;
                delete data.type;
                this.emit("R#" + type, data);
            })
        })

        this.socket.on("mines", (event) => {
            event.forEach((e) => {
                const { type } = e;
                let data = e;
                delete data.type;
                this.emit("M#" + type, data);
            })
        });

        this.socket.on("balance", (data) => {
            console.log(data);
            store.dispatch({
                type: "SET_BALANCE",
                payload: data.balance,
            });
        })
    }

    send(event, data) {
        this.socket.emit(event, data);
    }
}



const WebsocketClient = new Websocket();
export default WebsocketClient;



