import React from 'react'
import styled from "@emotion/styled";
import Colors from '../../Modules/Colors';
import { Typography } from "@mui/material";


const Card = styled.button`
        // change the background color to the ui_green color from the Colors module
        background-color: ${Colors.ui_grey};
        border-radius: 6px;
        border: none;
        color: white;
        padding: 4px 30px;
        font-family: "Roboto", sans-serif;
        font-size: 1.15rem;
        box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.45);
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: row;
        min-width: 110px;
    `;
export default function CoinDisplay({amount}) {
  
  return (
    <>
    <Card>
        <Typography variant="h6" fontWeight="600" component="div" sx={{mr: 1}}>{amount}</Typography>
        <img style={{
            width: "20px",
        }} src="https://media.discordapp.net/attachments/1188913827442589730/1212131831777071227/coingeb.png?ex=65f0b8bd&is=65de43bd&hm=a37c2af7a30091d78fa16e72a3654fed444f44297464158e1fef85cf8c924e03&=&format=webp&quality=lossless" alt="coin" />	
    </Card>
    </>
  )
}
