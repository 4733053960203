import React from "react";
import { Box } from "@mui/material";
import WebsocketClinet from "../../Modules/WebsocketClient";
import { useTheme, useMediaQuery } from "@mui/material";

// export default function BettingButton({ text, amount, color, gradient, canBet, bettingAmount }) {
//     return (
//         <Box
//             sx={{
//                 display: "flex",
//                 width: "100%",
//                 alignItems: "center",
//                 justifyContent: "space-between",
//                 backgroundColor: "rgb(34 197 94)",
//                 backgroundImage: gradient,
//                 borderRadius: ".25rem",
//                 flex: "1 1 auto",
//                 height: "4rem",
//                 position: "relative",
//                 boxSizing: "border-box",
//                 boxShadow:
//                     "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.75) 0px 0px 24px 0px inset",
//                 fontFamily: "Bai Jamjuree,sans-serif",
//                 fontWeight: 700,
//                 textAlign: "left",
//                 // darken if can't bet
//                 filter: canBet ? "brightness(1)" : "brightness(0.5)",
//                 // smooth transition
//                 transition: "filter 0.3s ease-in-out",
//                 cursor: canBet ? "pointer" : "not-allowed",
//                 fontSize: "1.2rem",
//                 lineHeight: "1.5rem",
//                 color: "rgba(0, 0, 0, 0.75)",
//                 padding: "1.5rem",
//             }}
//             onClick={() => {
//                 console.log("xxx");
//                 WebsocketClinet.emit("roulette", {
//                     type: "bet",
//                     amount: parseFloat(bettingAmount),
//                     color: color,
//                 });
//             }}
//         >
//             {text}
//             <div
//                 style={{
//                     fontSize: "1.4rem",
//                 }}
//             >
//                 {amount}x
//             </div>
//         </Box>
//     );
// }

export default function BettingButton({
    text,
    amount,
    color,
    gradient,
    canBet,
    bettingAmount,
}) {
    const isMobile = useMediaQuery("(max-width: 32rem)");
    const isMini = useMediaQuery("(max-width: 28rem)");

    const colorData = getColor(color);
    return (
        <Box
            sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "#1e1e1e",
                outline: `1.5px solid ${colorData.outline}`,
                // background gradient from top to bottom: top: 2C2362, bottom transparent
                backgroundImage: `linear-gradient(to bottom, ${colorData.background} 0%, transparent 100%)`,
                borderRadius: ".25rem",
                flex: "1 1 auto",
                height: "4rem",
                position: "relative",
                boxSizing: "border-box",
                fontFamily: "Chakra Petch, sans-serif",
                fontWeight: 700,
                textAlign: "left",
                // darken if can't bet
                filter: canBet ? "brightness(1)" : "brightness(0.5)",
                // smooth transition
                transition: "filter 0.3s ease-in-out",
                cursor: canBet ? "pointer" : "not-allowed",
                fontSize: "1.2rem",
                lineHeight: "1.5rem",
                padding: isMini ? ".8rem" : "1.5rem",
            }}
            onClick={() => {
                WebsocketClinet.send("roulette", [{
                    type: "bet",
                    amount: parseFloat(bettingAmount),
                    color: color,
                }]);
            }}
        >
            <span
                style={{
                    // gradient background from top to bottom: top: 4326B9 0%, bottom 3F346D
                    backgroundImage: `linear-gradient(to bottom, ${colorData.text1} 0%, ${colorData.text2} 100%)`,
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontSize: "1.3rem",
                }}
            >
                {isMobile ? text.split(" ")[1] : text}
            </span>
            <div
                style={{
                    fontSize: "1.4rem",
                    backgroundImage: `linear-gradient(to bottom, ${colorData.text1} 0%, ${colorData.text2} 100%)`,
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                }}
            >
                {amount}x
            </div>
        </Box>
    );
}

function getColor(color) {
    switch (color) {
        case "blue":
            return {
                outline: "#582FFF",
                background: "#2C2362",
                text1: "#48A9E0",
                text2: "#3D316D",
            };
        case "red":
            return {
                outline: "#F01010",
                background: "#A50909",
                text1: "#FF8484",
                text2: "#A50909",
            };
        case "gold":
            return {
                outline: "#E2AE02",
                background: "#B08A09",
                text1: "#FED650",
                text2: "#D5A404",
            };
    }
}

// export default function BettingButton({ text, amount, color, id }) {
//     return (
//         <Box
//             sx={{
//                 display: "flex",
//                 width: "100%",
//                 alignItems: "center",
//                 justifyContent: "space-between",
//                 backgroundColor: "#2F3438",

//                 borderRadius: ".25rem",
//                 flex: "1 1 auto",
//                 height: "4rem",
//                 position: "relative",
//                 boxSizing: "border-box",
//                 // boxShadow: "0px 0px 10px 1px rgba(0,255,68,0.9)",
//                 fontFamily: "Bai Jamjuree,sans-serif",
//                 fontWeight: 700,
//                 textAlign: "left",
//                 cursor: "pointer",
//                 fontSize: "1.2rem",
//                 lineHeight: "1.5rem",
//                 color: "rgba(0, 0, 0, 0.75)",
//                 outline: ".125rem solid #0074D9",
//                 // gradiend: bottom blue 10%, top transparent 90%
//                 backgroundImage: "linear-gradient(to bottom, transparent 90%, #0074D9 10%)",
//                 padding: "1.5rem",
//                 color: "white"
//             }}

//             onClick={() => {
//                 console.log("xxx")
//                 WebsocketClinet.io.emit("roulette_bet", { id });
//             }}
//         >

//             {text}
//             <div style={{
//                 fontSize: "1.4rem"
//             }}>{amount}x</div>
//         </Box>
//     );
// }
