import React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import BigNumber from "bignumber.js";

export default function PaymentSelector({ bettingAmount, setBettingAmount }) {
    const user = useSelector((state) => state.userReducer);
    // actions:

    function actionHalf() {
        let half = bettingAmount / 2;
        half = half < 0.01 ? 0.01 : half;
        half = Math.ceil(half * 100) / 100;
        setBettingAmount(half.toString());
    }

    function actionDouble() {
        let double = bettingAmount * 2;
        double = double > 1000 ? 1000 : double;
        double = Math.ceil(double * 100) / 100;
        setBettingAmount(double.toString());
    }

    function actionMin() {
        setBettingAmount("0.01");
    }

    function actionMax() {
        let bal = user.balance;
        if (!bal) return;
        bal = Math.floor(new BigNumber(bal).multipliedBy(100).toNumber()) / 100;
        bal = bal < 0.01 ? 0.01 : bal;
        bal = bal > 1000 ? 1000 : bal;
        setBettingAmount(bal.toString());
    }

    return (
        <Box
            sx={{
                width: { xs: "100%", sm: "auto" },
                display: "flex",
                flexDirection: "row",
                position: "relative",
                gap: "1rem",
                borderRadius: ".25rem",
                padding: ".75rem",
                backgroundColor: "rgb(31, 34, 37)",
                boxSizing: "border-box",
                fontFamily: "Bai Jamjuree,sans-serif",
            }}
        >
            <Box
                sx={{
                    gap: ".5rem",
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        position: "relative",
                        borderRadius: ".375rem",
                        backgroundColor: "rgb(31, 34, 37)",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <div style={{ width: "100%" }}>
                        <input
                            step="0.01"
                            min="0"
                            value={bettingAmount}
                            onChange={(e) => {
                                // remove all non-numeric characters that are not a period
                                let value = e.target.value;
                                value = value.replace(/[^0-9.]/g, "");
                                // remove a leading zero
                                value = value.replace(/^0+/, "");
                                // add a zero if the value starts with a period
                                value = value.replace(/^\./, "0.");
                                // add a zero if the value is empty
                                value = value === "" ? "0" : value;
                                // only allow two decimal places
                                value = value.match(/^\d*\.?\d{0,2}/)[0];
                                setBettingAmount(value);
                            }}
                            style={{
                                color: "#9ca3af",
                                borderRadius: ".25rem",
                                padding: ".625rem .75rem ",
                                fontSize: ".925rem",
                                outline: "none",
                                border: "none",
                                width: "100%",
                                outlineOffset: "2px",
                                lineHeight: "1.25rem",
                                paddingLeft: "2.5rem",
                                boxSizing: "border-box",
                                backgroundColor: "rgb(44, 48, 52)",
                            }}
                        />
                    </div>
                    <img src="https://rustyscrap.s3.eu-west-3.amazonaws.com/coingeb.png"
                    style={{
                        width: "1.3rem",
                        position: "absolute",
                        left: ".5rem",
                    }}
                    />
                </Box>

                <Box
                    sx={{
                        display: "grid",
                        flexShrink: 0,
                        gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
                        gap: ".5rem",
                        boxSizing: "border-box",
                    }}
                >
                    <PaymentButton text="1/2" action={actionHalf} />
                    <PaymentButton text="x2" action={actionDouble} />
                    <PaymentButton text="Min" action={actionMin} />
                    <PaymentButton text="Max" action={actionMax} />
                </Box>
            </Box>
        </Box>
    );
}

const PaymentButton = ({ text, action }) => {
    return (
        <button
            style={{
                borderWidth: "1px",
                borderColor: "transparent",
                borderRadius: ".25rem",
                padding: "0.625rem 1rem",
                backgroundColor: "rgb(44, 48, 52)",
                color: "rgb(132 139 141)",
                fontSize: "0.925rem",
                fontFamily: "Bai Jamjuree, sans-serif",
                fontWeight: "400",
                cursor: "pointer",
            }}
            onClick={action}
        >
            {text}
        </button>
    );
};
