import React from 'react'
import { Box } from '@mui/material'
import GameEntry from './GameEntry'
import RouletteIcon  from "../../Assets/Icons/roulette.png"
import MinesIcon from "../../Assets/Icons/mines.png"
import CoinflipIcon from "../../Assets/Icons/coinflip.png"

export default function GamesList() {
  return (
    <Box sx={{
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        flexDirection: "column",  
    }}>
        <Box className="GamesListRow1" sx={{
            height: "13rem",
            flexDirection: { xs: "column", md: "row" },
            display: "flex",
            gap: { xs: "1rem", md: "1.25rem" },
        }}>
            <GameEntry game="Roulette" href="/roulette" video="https://rustclash.com/assets/rust/homepage/game_cards/roulette.webm" icon={RouletteIcon}/>
            <GameEntry game="Mines" href="/mines" video="https://rustclash.com/assets/rust/homepage/game_cards/mines.webm" icon={MinesIcon}/>
            <GameEntry game="Coinflip" href="/coinflip" video="https://rustclash.com/assets/rust/homepage/game_cards/battle.webm" icon={CoinflipIcon}/>
        </Box>

    </Box>
  )
}
