
const Colors = {
    grey1: "#1F2225",
    grey2: "#1A1D20",
    grey3: "#151719",

    ui_grey: "#2F3438",
    ui_green: "#038100"
}

export default Colors;