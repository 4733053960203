import React from 'react'
import { Box } from '@mui/material'
import GamesList from './GamesList'

export default function HomePage() {
  return (
    <Box sx={{
      
      isolation: "isolate",
      minHeight: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      width: "100%",
    }}>
      
        <GamesList />
      </Box>

  )
}
