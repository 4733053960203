import React from "react";
import { useNavigate } from "react-router-dom";
import { store } from "../index";
import AxiosClient from "../Modules/AxiosClient"

let setUserLoaded = () => {};

export default function Authentication() {
  const navigate = useNavigate();
  React.useEffect(() => {
    (async () => {
      try {
        const res = await AxiosClient.get("/auth/check", {
          withCredentials: true,
        });
        setUserLoaded(true);
        if (!res.data.error) {
          // dispatch userReducer
          store.dispatch({
            type: "SET_USER",
            payload: {
              isLoggedIn: true,
              isInitialized: true,
              steamid: res.data.steamid,
              avatar: res.data.avatar,
              username: res.data.username,
              name: res.data.name,
              balance: res.data.balance,
            },
          });
  
          console.log(res.data);
        } else {
          store.dispatch({
            type: "SET_USER",
            payload: {
              isLoggedIn: false,
              isInitialized: true,
            },
          });
        }
      } catch (error) {
        console.log(error);
        store.dispatch({
          type: "SET_USER",
          payload: {
            isLoggedIn: false,
            isInitialized: true,
          },
        });
      }
    })();
  }, []);

  return;
}
