import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import WebsocketClient from "../../Modules/WebsocketClient";

export default function PaymentSelector({ status, multiplier }) {
    const [bet, setBet] = React.useState("0.10");
    const [mines, setMines] = React.useState(1);

    function BetButton() {
        return (
            <Button
                onClick={() => {
                    WebsocketClient.send("mines", [
                        { type: "new", bet: parseFloat(bet), mines: parseInt(mines) },
                    ]);
                }}
                sx={{
                    borderRadius: ".25rem",
                    fontSize: ".875rem",
                    backgroundColor: "rgb(54 89 232)",
                    backgroundImage:
                        "repeating-linear-gradient(-45deg, transparent, transparent 1rem, rgba(21, 23, 25, .1) 0, rgba(21, 23, 25, .1) 2rem)",
                    fontWeight: "700",
                    position: "relative",
                    width: "100%",
                    padding: ".875rem 1rem",
                }}
            >
                Bet
            </Button>
        );
    }

    return (
        <Box
            sx={{
                display: "block",
            }}
        >
            <form style={{ height: "100%" }}>
                <Box
                    sx={{
                        width: { xs: "100%", md: "20rem" },
                        padding: "2rem",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1.5rem",
                        height: "100%",
                        backgroundColor: "rgb(26 29 32)",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                        }}
                    >
                        <label htmlFor="bet">Bet</label>
                        <input
                            value={bet}
                            style={{
                                width: "100%",
                                backgroundColor: "rgb(44, 48, 52)",
                                border: "none",
                                borderRadius: ".25rem",
                                padding: ".8rem 1rem",
                                color: "white",
                            }}
                            onChange={(e) => {
                                let value = e.target.value;
                                value = value.replace(/[^0-9.]/g, "");
                                value = value.replace(/^0+/, "");
                                value = value.replace(/^\./, "0.");
                                value = value === "" ? "0" : value;
                                value = value.match(/^\d*\.?\d{0,2}/)[0];
                                setBet(value);
                            }}
                            onBlur={(e) => {
                                if (parseFloat(e.target.value) < 0.1) {
                                    setBet("0.10");
                                }
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                        }}
                    >
                        <label htmlFor="mines">Mines</label>
                        <input
                            style={{
                                width: "100%",
                                backgroundColor: "rgb(44, 48, 52)",
                                border: "none",
                                borderRadius: ".25rem",
                                padding: ".8rem 1rem",
                                color: "white",
                            }}
                            value={mines}
                            onChange={(e) => {
                                // only allow 1-24 mines, only ints and no other symbols/leading 0
                                const value = e.target.value
                                    .replace(/\D/g, "")
                                    .replace(/^0+/g, "")
                                    .replace(/25+/g, "24");
                                if (parseInt(value) > 24) {
                                    setMines("24");
                                } else {
                                    setMines(value);
                                }
                            }}
                            onBlur={(e) => {
                                if (e.target.value === "") {
                                    setMines("1");
                                }
                            }}
                        />
                    </Box>

                    {status == "running" ? <CashoutButton /> : <BetButton />}
                    <h4>
                        Multiplier: <br />
                        {multiplier}
                    </h4>
                </Box>
            </form>
        </Box>
    );
}

function CashoutButton() {
    return (
        <Button
            onClick={() => {
                WebsocketClient.send("mines", [{ type: "cashout" }]);
            }}
            sx={{
                borderRadius: ".25rem",
                fontSize: ".875rem",
                backgroundColor: "rgb(54 89 232)",
                backgroundImage:
                    "repeating-linear-gradient(-45deg, transparent, transparent 1rem, rgba(21, 23, 25, .1) 0, rgba(21, 23, 25, .1) 2rem)",
                fontWeight: "700",
                position: "relative",
                width: "100%",
                padding: ".875rem 1rem",
            }}
        >
            Cashout
        </Button>
    );
}
