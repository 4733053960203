import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import HomePage from "./Pages/HomePage";
import NavigationBar from "./Compontents/Navbar";
import Colors from "./Modules/Colors";
import RoulettePage from "./Pages/RoulettePage";
import Authentication from "./Compontents/Authentication";
import MinesPage from "./Pages/MinesPage";
import { Box } from "@mui/system";

// WS
import "./Modules/WebsocketClient";
import CallbackPage from "./Pages/Auth/CallbackPage";

const darkTheme = createTheme({
    palette: {
        mode: "dark",

        background: {
            default: Colors.grey3,
        },
    },
});

function App() {
    return (
        <>
            <ThemeProvider theme={darkTheme}>
                <CssBaseline />

                <Router>
                    <Authentication />
                    <Box
                        sx={{
                            display: "flex",
                            height: "100vh",
                            width: "100vw",
                            flexDirection: "column",
                        }}
                    >
                        <NavigationBar />
                        <Box
                            className="Content"
                            sx={{
                                width: "100%",
                                height: "100%",
                                overflow: "auto",
                                position: "relative",
                                flexGrow: 1,
                            }}
                        >
                            <Box
                                className="Main"
                                sx={{
                                    padding: {
                                        xs: ".9rem",
                                        sm: "1.5rem",
                                        md: "2.5rem",
                                    },
                                    minHeight: "100%",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    isolation: "isolate",
                                    maxWidth: "80rem",
                                }}
                            >
                                <Routes>
                                    <Route
                                        exact
                                        path="/"
                                        element={<HomePage />}
                                    />
                                    <Route
                                        exact
                                        path="/roulette"
                                        element={<RoulettePage />}
                                    />
                                    <Route
                                        exact
                                        path="/mines"
                                        element={<MinesPage />}
                                    />

                                    <Route
                                        path="/auth/callback"
                                        element={<CallbackPage />}
                                    />

                                    <Route
                                        path="*"
                                        element={
                                            <div
                                                style={{ textAlign: "center" }}
                                            >
                                                This Page doesn't exist!
                                            </div>
                                        }
                                    />
                                </Routes>
                            </Box>
                        </Box>
                    </Box>
                </Router>
            </ThemeProvider>
        </>
    );
}

export default App;
